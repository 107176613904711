$(function () {
  // ver1.4.0
  //初期表示、及びバリデーションエラー後の読み込みで、選択したプラン以外をdisabledにする
  let buyer_member_type = "";
  $('input[name="buyer_contract[plan_id]"]').prop("disabled", true);
  buyer_member_type = $('input[name="buyer_information[member_type]"]:checked').val();
  $('.plan--' + buyer_member_type).find(':hidden').prop("disabled", false);

  // 使用しないためコメントアウト
  /*
  if ($('#new_buyer_contract')[0]) {
    // 会員種別（大カテゴリ）の選択によって、小カテゴリの選択肢を切り替える
    $('.buyer_information_member_type').on('change', function() {
      $('.buyer_information_member_subtype').prop('checked', false);
      $('.member_subtypes_area').hide();
      var memberType = $(this).val();
      $('.member_subtypes_area--' + memberType).show();
      jpf = $('.jp');
      enf = $('.en');
      if (memberType == 'global') {
        jpf.removeClass('open');
        jpf.addClass('close');
        enf.removeClass('close');
        enf.addClass('open');
      } else {
        jpf.removeClass('close');
        jpf.addClass('open');
        enf.removeClass('open');
        enf.addClass('close');
      }
    });

    $('input[name="buyer_information[member_type]"]').on('click' ,function(){
      $('.optionttl').find(':checkbox').prop("checked", false);
      $('input[name="buyer_contract[plan_id]"]').prop("disabled", true);
      $('.plan_area').hide();
      buyer_member_type = $(this).val();
      $('.plan--' + buyer_member_type).show();
      $('.plan--' + buyer_member_type).find(':hidden').prop("disabled", false);
    });

    $('input[name="agreement"]').on('change', function() {
      if ( $(this).prop('checked') == false ) {
        $('input[name="commit"]').attr('disabled', 'disabled');
      } else {
        $('input[name="commit"]').removeAttr('disabled');
      }
    });

    $('.clear').on('click' ,function(){
      // input file をクリア
      $(this).parent('div').parent().children('input').val('');
      // cache削除
      $(this).parent('div').parent().prev('dt').children('input[name*="_cache"]').val('');
      // サムネイル削除
      $(this).parent('div').next('.media').html('');
    });
  }
  */
});
